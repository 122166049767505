import request from '@/utils/request'
import qs from "qs";

export function list(data) {
  data=qs.stringify(data)
  return request({
    url: '/FreeQuery',
    method: 'POST',
    data
  })
}
export function detail(data) {
  data=qs.stringify(data)
  return request({
    url: '/Get',
    method: 'POST',
    data
  })
}
export function love(data) {
  data=qs.stringify(data)
  return request({
    url: '/ChangeNumber',
    method: 'POST',
    data
  })
}
export function updateAll(data) {
  data=qs.stringify(data)
  return request({
    url: '/FreeUpdate',
    method: 'POST',
    data
  })
}

