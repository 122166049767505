<template>
  <div class="web">
    <my-header @search="search" @sidebar="sidebar"></my-header>
<!--    <router-view :grade="grade" :dynasty="dynasty" :poem="poem" :poet="poet"></router-view>-->
    <router-view></router-view>
    <search v-model="show"></search>
    <sidebar v-model="show1"></sidebar>
  </div>
</template>
<script>
import '@/assets/css/font.css'
import '@/assets/css/lee.css'
import '@/assets/css/lee-mb.css'
import MyHeader from "@/components/MyHeader";
import Search from "@/components/Search";
import Sidebar from "@/components/Sidebar";

export default {
    name: 'myweb',
    components: {
        MyHeader,
        Search,
        Sidebar
    },
    data() {
        return {
            show: false,
            show1: false,
            // grade:this.$store.state.articles.grade,
            // dynasty:this.$store.state.articles.dynasty,
            // poem:this.$store.state.articles.poem,
            // poet:this.$store.state.articles.poet
        }
    },
    mounted() {
        
    },
    created() {
        // let formData1 = {
        //     app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
        //     model_name: 'grade',
        //     perpage: 10000,
        // }
        // let formData2 = {
        //     app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
        //     model_name: 'dynasty',
        //     perpage: 10000,
        // }
        // let formData3 = {
        //     app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
        //     model_name: 'poem',
        //     perpage: 10000,
        // }
        // let formData4 = {
        //     app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
        //     model_name: 'poet',
        //     perpage: 10000,
        // }
        //
        // if (this.$store.state.articles.grade.length === 0) {
        //     this.$store.dispatch('articles/getList', formData1).then((rs)=>{
        //         this.grade=rs
        //     })
        // }else{
        //     this.grade=this.$store.state.articles.grade
        // }
        // if (this.$store.state.articles.dynasty.length === 0) {
        //     this.$store.dispatch('articles/getList', formData2).then((rs)=>{
        //         this.dynasty=rs
        //
        //     })
        // }else{
        //     this.dynasty=this.$store.state.articles.dynasty
        // }
        // if (this.$store.state.articles.poem.length === 0) {
        //     this.$store.dispatch('articles/getList', formData3).then((rs)=>{
        //         this.poem=rs
        //
        //     })
        // }else{
        //     this.poem=this.$store.state.articles.poem
        // }
        // if (this.$store.state.articles.poet.length === 0) {
        //     this.$store.dispatch('articles/getList', formData4).then((rs)=>{
        //         this.poet=rs
        //     })
        // }else{
        //     this.poet=this.$store.state.articles.poet
        // }


    },
    methods: {
        search() {
            this.show = true
        },
        sidebar() {
            this.show1 = true
        }
    },
    watch: {
        $route: {
            handler() {
                this.show = false
                this.show1 = false

            },
            immediate: true
        }
    },
}
</script>
