<template>
  <div class="sidebar">
    <transition name="fade">
      <div class="mark1" v-if="value" @click="hide"></div>
    </transition>
      <div class="sidebarN " :class="{exp:value}">
        <router-link class="logo" to="/"><i></i></router-link>
        <nav>
        <ul class="f20">
          <router-link to="/poem"  tag="li">诗词</router-link>
          <router-link to="/poet"  tag="li">诗人</router-link>
        </ul>
      </nav>
      </div>
  </div>
</template>
<script>
export default {
    name: 'Sidebar',
    data() {
        return {

        }
    },
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    mounted() {


    },
    methods: {
        hide() {
            this.$emit('input', false)
        }
    },
    computed: {}
}
</script>
