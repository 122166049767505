import {list} from '@/api/api'
//import {poem,poet,grade,dynasty} from '@/mydata.js'


const state = {
    poem: [],
    poet: [],
    grade: [],
    dynasty: [],
}

const mutations = {
    getPoem: (state, rs) => {
        state.poem = rs
    },
    getPoet: (state, rs) => {
        state.poet = rs
    },
    getGrade: (state, rs) => {
        state.grade = rs
    },
    getDynasty: (state, rs) => {
        state.dynasty = rs
    }

}

const actions = {
    getList({commit}, sendData) {
        let model_name = sendData.model_name
        return new Promise((resolve, reject) => {
            list(sendData).then((rs) => {
                const {data} = rs
                if (model_name === 'poem') {
                    commit('getPoem', data.list)
                } else if (model_name === 'poet') {
                    commit('getPoet', data.list)
                }else if (model_name === 'grade') {
                    commit('getGrade', data.list)
                }else if (model_name === 'dynasty') {
                    commit('getDynasty', data.list)
                }
                resolve(data.list)
            }).catch(error => {
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

