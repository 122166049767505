<template>
  <div class="searchArea">
    <transition name="fade">
      <div class="mark" @click="hide" v-if="value"></div>
    </transition>
    <transition name="scale">
      <div class="searchWin" v-if="value">
        <div class="searchInput">
          <div class="tag f12">Esc</div>
          <input class="f18" placeholder="查找作者、诗词、名句" v-model="keyword"/>
        </div>
        <ul class="resultlist">
          <router-link class="f18" v-for="(item,xh) in result" :key="xh" tag="li" :to="'/poem/'+item.id">
            <span>{{item.poem}}</span>
            <span class="st">|</span>
            <router-link :to="'/poet/'+getPoetId(item.poet)">{{item.poet}}</router-link>
          </router-link>
        </ul>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
    name: 'Search',
    data() {
        return {
            keyword: ''

        }
    },
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        document.addEventListener('keyup', (e) => {
            if (e.code === 'Escape' && this.value) {
                this.hide()
            }
        })


    },
    methods: {
        hide() {
            this.$emit('input', false)
        },
        getPoetId(text) {
            let fi = this.$store.state.articles.poet.filter((el) => {
                return el.poet === text
            })
            if (fi.length > 0) {
                return fi[0].id
            } else {
                return ''
            }
        },
    },
    computed: {
        result() {
            let fi
            let poem = this.$store.state.articles.poem
            if (this.keyword) {
                fi = poem.filter((el) => {
                    return el.poem.indexOf(this.keyword) > -1 || el.poet.indexOf(this.keyword) > -1 || el.content.indexOf(this.keyword) > -1
                })
            }

            return fi

        }
    },
    watch: {
        value: {
            immediate: true,
            handler() {
               this.keyword=''
            }
        }
    }
}
</script>
