<template>
  <header>
    <div class="container">
      <div class="ll">
        <div @click="tan1" class="sidebar-button">
          <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" viewBox="0 0 448 512" class="icon">
            <path fill="currentColor"
                  d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z"></path>
          </svg>
        </div>
        <router-link class="logo" to="/"><i></i></router-link>
        <nav>
          <ul class="f20">
            <router-link to="/poem" tag="li">诗词</router-link>
            <router-link to="/poet" tag="li">诗人</router-link>
          </ul>
        </nav>
      </div>
      <div class="rr">
        <div class="search" @click="tan">
          <div class="f16 input">查找作者、诗词、名句</div>
          <div class="button f16">搜索</div>
        </div>
        <audio src="@/assets/bg.mp3" autoplay ref="music"></audio>
        <div class="voice" @click="playVoice"><img src="@/assets/myimg/closeVolume.png" v-if="!play"><img src="@/assets/myimg/openVolume.png" v-if="play"></div>
      </div>
    </div>
  </header>
</template>
<script>
export default {
    name: 'myHeader',
    data() {
        return {
            play:false
        }
    },
    mounted() {

    },
    methods: {
        playVoice(){
            if(this.$refs.music.paused){
                this.play=true
                this.$refs.music.play()
            }else{
                this.play=false
                this.$refs.music.pause()
            }
        },
        tan() {
            this.$emit('search')
        },
        tan1() {
            this.$emit('sidebar')
        },
    },
    computed: {}
}
</script>
