import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
    {
        path: '/',
        //redirect: '/poem',
        component: () => import('@/views/index'),
        meta: {title: '首页'}
    },
    {
        path: '/poem',
        component: () => import('@/views/poem'),
        meta: {title: '诗词'}
    },
    {
        path: '/poet',
        component: () => import('@/views/poet'),
        meta: {title: '作者'}
    },

    {
        path: '/poem/:id',
        component: () => import('@/views/poemdetail'),
        meta: {title: '诗词详情', detail: true}
    },
    {
        path: '/poet/:id',
        component: () => import('@/views/poetdetail'),
        meta: {title: '作者详情', detail: true}
    },
    {
        path: '/404',
        component: () => import('@/views/404'),
        meta: {title: '404'}
    },
    {path: '*', redirect: '/404'}
]

const router = new Router({
    routes,
    linkActiveClass: 'active',
    linkExactActiveClass: 'exact-active',
    scrollBehavior() {
        return {x: 0, y: 0};
    },
})
export default router
