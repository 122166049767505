import router from '@/router'
import getPageTitle from '@/utils/getPageTitle'
import store from '@/store'
router.afterEach((to) => {
    let which=''
    if (to.params.id) {
        if(to.path.indexOf('poet')>-1){
            let fi=store.state.articles.poet.filter((el)=>{
                return el.id==to.params.id
            })
            if(fi.length>0){
               which=fi[0].poet
            }

        }else if(to.path.indexOf('poem')>-1){
            let fi=store.state.articles.poem.filter((el)=>{
                return el.id==to.params.id
            })
            if(fi.length>0){
               which=fi[0].poem
            }

        }
        document.title = getPageTitle(to.meta.title + '-' + which)
    } else {
        document.title = getPageTitle(to.meta.title)
    }
})
